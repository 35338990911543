<template lang="pug">
b-container(fluid)
  b-row.mb-3
    b-col
      h3 Files {{ pathMatch }}
      
      audio-player(v-if="audioFile" :src="audioFile", width='100%')

      table.table
        thead
        tbody(v-if='loading')
          tr
            td
              b-spinner
        tbody(v-else)
          tr(v-if="pathMatch != '/'")
            td(width='40')
            td(colspan=4)
              router-link(:to="{ params: { pathMatch: pathMatch.substring(0, pathMatch.lastIndexOf('/', pathMatch.length-2) + 1) } }")
                | Up

          tr(v-if="pathMatch == '/' && items.length == 0 && createDefaultFileStructure")
            td 
              p No files found.
              b-button(@click='createDefaultFileStructure') Create default file structure
          tr(v-for='item, index in itemsAndUploads', :key="item.key")
            td(width='40')
              c-icon(:name='itemIcon(item)')

            td 
              template(v-if='item.extension == "jpg"')
                a(href='', @click.prevent="$bvModal.show('img-modal-' + item.name)")
                  img.align-top.mr-3.img-thumbnail(
                    :src='item.downloadUrl'
                    width='100'
                  )
                
                b-modal(
                  :id="'img-modal-' + item.name"
                  ok-only
                )
                  img.img-fluid(
                    :src='item.downloadUrl'
                  )

              router-link(v-if="item.type == 'directory'" :to='{ params: { pathMatch: itemPath(item) } }') {{ item.name }}
              a(v-else-if="item.downloadUrl", :href='item.downloadUrl') {{ item.name }}
              template(v-else) {{ item.name }}

              template(v-if='item.uploadPercentage') 
                span  (Uploading {{ item.uploadPercentage }} %)
                b-spinner.ml-3(small)

              template(v-if='item.wavinfo && item.wavinfo.channels')
                | &nbsp;(
                span.font-weight-bold
                  | {{ item.wavinfo.bitsPerSample }}bps
                | /
                span.font-weight-bold {{ item.wavinfo.sampleRate }}Hz
                | /
                span.font-weight-bold
                  template(v-if="item.wavinfo.channels == 1")
                    | Mono

                  template(v-if="item.wavinfo.channels == 2")
                    | Stereo
                  
                  template(v-if="![1,2].includes(item.wavinfo.channels)")
                    | {{ item.wavinfo.channels }}ch

                | )&nbsp;
                a(href='', @click.prevent='audioFile = item.downloadUrl') Play audio
              
              template(v-if='showMoveToProduction')
               | &nbsp;|&nbsp;
               a(href='', @click.prevent='moveToProduction(item)') Move to production


            td
              span(:id="`fileItem-${index}`")
                template(v-if="item.fileCount") {{ item.fileCount }}&nbsp;/&nbsp;
                template(v-if="item.size") {{ numeral(item.size).format('0.0 b') }}

              b-popover(
                v-if="item.fileCount"
                :target="`fileItem-${index}`"
                triggers="hover"
                placement="bottom"
              )
                ul 
                  li(v-for='file in item.fileList') {{ file }}


            td {{ item.mtime|datetimeFormat }}
            td 
              b-button(v-if="item.type != 'directory'", @click='deleteItem(item)')
                b-icon-trash()
  b-row(v-if='writable')
    b-col 
      file-upload(
        :url='apiUrl'
        @update='fileUpdate' 
      )         

</template>

<script>
  import numeral from 'numeral'

  export default {
    name: 'FileManager',
    components: {
      AudioPlayer: () => import('@/components/AudioPlayer'),
      'file-upload': () => import('@/components/FileUpload'),
    },
    props: {
      pathMatch: { type: String, default: '' },
      holder: Object,
      holderType: String,
      holderId: String,
    },
    data () {
      return {
        audioFile: '',
        loading: true,
        writable: false,
        items: [],
        uploads: {},
      }
    },
    inject: [
      'createDefaultFileStructure'
    ],
    watch: {
      $route() {
        this.loadPath()
      }
    },
    computed: {
      apiUrl () { return `files/${this.holderType}/${this.holderId}${this.pathMatch}` },
      itemsAndUploads () {
        const items = JSON.parse(JSON.stringify(this.items)).map(item => {
          if (item.children) {
            item.size = 0
            item.fileCount = 0
            item.dirCount = 0
            item.fileList = []

            for (const stack = item.children.map(child => [`/${child.name}`, child]); stack.length;) {
              const [path, curr] = stack.pop()
              
              if (curr.type == 'directory') {
                item.dirCount++
              } else {
                item.fileCount++
                item.size += curr.size
                item.fileList.push(path)
              }

              if (curr.children)
                stack.push(...curr.children.map(child => [`${path}/${child.name}`, child]))
            }
          }

          return item
        })

        return items.concat(Object.values(this.uploads))
      },
      showMoveToProduction () {
        return this.pathMatch.match(/^\/(Artwork|Audio)\/(Digital|Vinyl|CD)\/$/) !== null
      },
    },
    methods: {
      async moveToProduction (item) {
        if (!confirm('Are you sure you want to move this file to Production Files?')) return
        this.loading = true

        const url = this.apiUrl + encodeURIComponent(item.name)
        
        await this.$http.patch(url, { action: 'moveToProduction' })

        this.loadPath(true)
      },
      fileUpdate (file) {
        const key = 'new-' + file.name
        if (file.uploadComplete) {
          delete this.uploads[key]
          this.loadPath(true)
          return
        }

        this.$set(this.uploads, key, {
          key,
          name: file.name,
          size: file.size,
          uploadPercentage: file.uploadPercentage
        })
      },
      numeral,
      itemIcon (item) {
        if (item.type == 'directory')
          return 'cil-folder'
        return 'cil-file'
      },
      itemPath (item) {
        return `${this.pathMatch}${item.name}` + (item.type == 'directory' ? '/' : '')
      },
      async deleteItem(item) {
        if (!confirm('Are you sure you want to delete this file?')) return
        this.loading = true

        const url = this.apiUrl + encodeURIComponent(item.name)
        
        await this.$http.delete(url)
        this.loadPath(true)
      },
      async loadPath (update) {

        if (!update) {
          this.loading = true
          this.items.length = 0
        }
       
        const response = await this.$http.get(this.apiUrl)
        this.writable = response.data.writable
        this.items = response.data.items.filter(f => f.name !== '_deleted')
        this.loading = false
      }
    },
    mounted () {
      this.loadPath()  
    }
  }
</script>

