var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            [
              _c("h3", [_vm._v("Files " + _vm._s(_vm.pathMatch))]),
              _vm.audioFile
                ? _c("audio-player", {
                    attrs: { src: _vm.audioFile, width: "100%" }
                  })
                : _vm._e(),
              _c("table", { staticClass: "table" }, [
                _c("thead"),
                _vm.loading
                  ? _c("tbody", [_c("tr", [_c("td", [_c("b-spinner")], 1)])])
                  : _c(
                      "tbody",
                      [
                        _vm.pathMatch != "/"
                          ? _c("tr", [
                              _c("td", { attrs: { width: "40" } }),
                              _c(
                                "td",
                                { attrs: { colspan: "4" } },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          params: {
                                            pathMatch: _vm.pathMatch.substring(
                                              0,
                                              _vm.pathMatch.lastIndexOf(
                                                "/",
                                                _vm.pathMatch.length - 2
                                              ) + 1
                                            )
                                          }
                                        }
                                      }
                                    },
                                    [_vm._v("Up")]
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm.pathMatch == "/" &&
                        _vm.items.length == 0 &&
                        _vm.createDefaultFileStructure
                          ? _c("tr", [
                              _c(
                                "td",
                                [
                                  _c("p", [_vm._v("No files found.")]),
                                  _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: _vm.createDefaultFileStructure
                                      }
                                    },
                                    [_vm._v("Create default file structure")]
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm._l(_vm.itemsAndUploads, function(item, index) {
                          return _c("tr", { key: item.key }, [
                            _c(
                              "td",
                              { attrs: { width: "40" } },
                              [
                                _c("c-icon", {
                                  attrs: { name: _vm.itemIcon(item) }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                item.extension == "jpg"
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.$bvModal.show(
                                                "img-modal-" + item.name
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "align-top mr-3 img-thumbnail",
                                            attrs: {
                                              src: item.downloadUrl,
                                              width: "100"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-modal",
                                        {
                                          attrs: {
                                            id: "img-modal-" + item.name,
                                            "ok-only": ""
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "img-fluid",
                                            attrs: { src: item.downloadUrl }
                                          })
                                        ]
                                      )
                                    ]
                                  : _vm._e(),
                                item.type == "directory"
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            params: {
                                              pathMatch: _vm.itemPath(item)
                                            }
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  : item.downloadUrl
                                  ? _c(
                                      "a",
                                      { attrs: { href: item.downloadUrl } },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  : [_vm._v(_vm._s(item.name))],
                                item.uploadPercentage
                                  ? [
                                      _c("span", [
                                        _vm._v(
                                          " (Uploading " +
                                            _vm._s(item.uploadPercentage) +
                                            " %)"
                                        )
                                      ]),
                                      _c("b-spinner", {
                                        staticClass: "ml-3",
                                        attrs: { small: "" }
                                      })
                                    ]
                                  : _vm._e(),
                                item.wavinfo && item.wavinfo.channels
                                  ? [
                                      _vm._v(" ("),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            _vm._s(item.wavinfo.bitsPerSample) +
                                              "bps"
                                          )
                                        ]
                                      ),
                                      _vm._v("/"),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            _vm._s(item.wavinfo.sampleRate) +
                                              "Hz"
                                          )
                                        ]
                                      ),
                                      _vm._v("/"),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          item.wavinfo.channels == 1
                                            ? [_vm._v("Mono")]
                                            : _vm._e(),
                                          item.wavinfo.channels == 2
                                            ? [_vm._v("Stereo")]
                                            : _vm._e(),
                                          ![1, 2].includes(
                                            item.wavinfo.channels
                                          )
                                            ? [
                                                _vm._v(
                                                  _vm._s(
                                                    item.wavinfo.channels
                                                  ) + "ch"
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      ),
                                      _vm._v(") "),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              _vm.audioFile = item.downloadUrl
                                            }
                                          }
                                        },
                                        [_vm._v("Play audio")]
                                      )
                                    ]
                                  : _vm._e(),
                                _vm.showMoveToProduction
                                  ? [
                                      _vm._v(" | "),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.moveToProduction(item)
                                            }
                                          }
                                        },
                                        [_vm._v("Move to production")]
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            ),
                            _c(
                              "td",
                              [
                                _c(
                                  "span",
                                  { attrs: { id: "fileItem-" + index } },
                                  [
                                    item.fileCount
                                      ? [_vm._v(_vm._s(item.fileCount) + " / ")]
                                      : _vm._e(),
                                    item.size
                                      ? [
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .numeral(item.size)
                                                .format("0.0 b")
                                            )
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                ),
                                item.fileCount
                                  ? _c(
                                      "b-popover",
                                      {
                                        attrs: {
                                          target: "fileItem-" + index,
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _c(
                                          "ul",
                                          _vm._l(item.fileList, function(file) {
                                            return _c("li", [
                                              _vm._v(_vm._s(file))
                                            ])
                                          }),
                                          0
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm._f("datetimeFormat")(item.mtime))
                              )
                            ]),
                            _c(
                              "td",
                              [
                                item.type != "directory"
                                  ? _c(
                                      "b-button",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteItem(item)
                                          }
                                        }
                                      },
                                      [_c("b-icon-trash")],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        })
                      ],
                      2
                    )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.writable
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("file-upload", {
                    attrs: { url: _vm.apiUrl },
                    on: { update: _vm.fileUpdate }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }